import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import i18n from '../i18n';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const theme = {
  primary: '#17638D',
  secondary: 'red',
  accent: '#9C27b0',
  red:'#9C27b0',
  info: '#00CAE3'
}

export default new Vuetify({
  rtl:true,
  primary: {
    base: colors.purple.base,
    darken1: colors.purple.darken2,
  },
  secondary: colors.indigo,
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  tertiary: colors.pink.base,
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },

})
