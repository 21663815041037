import Vue from 'vue'
import Router from "vue-router";
// import VueMeta from 'vue-meta';
import store from './store';

Vue.use(Router)
// Vue.use(VueMeta)



const router = new Router({
  //mode: "history",
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
  
    routes: [
    

      {
        path: '/auth',
        name:'auth',
        component: () => import('./views/pages/Index.vue'),
        children: [{
          name: 'Login',
          path: '',
          component: () => import('./views/pages/Login.vue')
        }]
      },
      {
        path: '/',
        component: () => import('./views/dashboard/Index.vue'),
        beforeEnter: (to, from, next) => {
          
           if (localStorage.getItem('tokinn') && store.state.AdminInfo.authe==true) {
            next()
          } else {
            next({
              name: 'Login'
            });
          }
        },   
        children: [
          // {
          //   name: 'statistics',
          //   path: 'statistics',
          //   component: () => import('./views/dashboard/Dashboard.vue')
          // },

          

          {
            path: 'about',
            name:'about',
            component: () => import('./views/dashboard/about.vue'),
            
          },

          

            {
            path: 'options',
            name:'options',
            component: () => import('./views/dashboard/options.vue'),
            
          },



          // {
          //   path: 'Gallery',
          //   name:'Gallery',
          //   component: () => import('./views/dashboard/Gallery.vue'),
            
          // },

          {
            path: 'GalleryCats',
            name:'GalleryCats',
            component: () => import('./views/dashboard/GalleryCats.vue'),
            
          },

          {
            path: 'VideosCategories',
            name:'VideosCategories',
            component: () => import('./views/dashboard/VideosCategories.vue'),
            
          },
          //GalleryCats.vue
          
          {
            path: 'articles',
            name:'articles',
            component: () => import('./views/dashboard/articles.vue'),
            
          },
          {
            path: 'messages',
            name:'messages',
            component: () => import('./views/dashboard/messages.vue'),
            
          },

        

          {
            path: 'videos',
            name:'videos',
            component: () => import('./views/dashboard/videos.vue'),

          },
          {
            path: 'newsletter',
            name:'newsletter',
            component: () => import('./views/dashboard/newsletter.vue'),

          },
          {
            path: 'comments',
            name:'comments',
            component: () => import('./views/dashboard/comments.vue'),

          },
       
          //newsletter
          {
            path:'libraryImage',
            name:'libraryImage',
            component: () => import('./views/dashboard/libraryImage.vue'),
          },
          {
            path:'callUs',
            name:'callUs',
            component: () => import('./views/dashboard/callUs.vue'),
          }


       
        ]   
        
      },
    
    
    
       
       
    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

